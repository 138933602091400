import { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { baseAxiosInstance } from '../axios';

type RequestType = {
  url: string;
  params?: unknown;
  payload?: unknown;
  config?: AxiosRequestConfig;
  responseType?: string;
};

export type HttpError = AxiosError;

class HttpService {
  axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  get({ url, params, config }: RequestType): Promise<AxiosResponse> {
    return this.axiosInstance.get(url, { params, ...config });
  }

  post({ url, payload, config }: RequestType): Promise<AxiosResponse> {
    return this.axiosInstance.post(url, payload, config);
  }

  put({ url, payload, config }: RequestType): Promise<AxiosResponse> {
    return this.axiosInstance.put(url, payload, config);
  }

  patch({ url, payload, config }: RequestType): Promise<AxiosResponse> {
    return this.axiosInstance.patch(url, payload, config);
  }

  delete({ url }: RequestType): Promise<AxiosResponse> {
    return this.axiosInstance.delete(url);
  }

  setAuthHeader({ jWT }: { jWT: string }): void {
    this.axiosInstance.defaults.headers.common = { Authorization: `Bearer ${jWT}` };
    localStorage.setItem('SYNERGY_API_KEY', jWT);
  }
}

export const httpService = new HttpService(baseAxiosInstance);
