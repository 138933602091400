import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { Dispatch, useEffect } from 'react';
import { useContext } from 'react';
import { useState, useCallback } from 'react';
import { createContext } from 'react';
import { useIdleTimer } from 'react-idle-timer';

// services
import { httpService } from 'modules/core/services/http.service';
import { getDataUser, refreshToken } from './services/auth.service';
import { RoleInterface } from 'modules/users/interfaces/users.interface';
import { useSnackbar } from 'notistack';
import { parseSessionTime } from 'utils/parseTime';
import { getFile } from 'modules/profile/services/UserImage.services';
import { TeamInterface } from 'modules/shared/interfaces';

export interface Ability {
  name?: string;
  id: string;
  action?: string;
  description?: string;
  permissions?: Permissions[];
  PermissionObject?: Permissions;
}

export interface Permissions {
  description: string;
  id: string;
  name: string;
}

export interface AbilityConfig {
  name: string;
  id: string;
}
export interface UserData {
  username: string;
  firstName: string;
  lastName: string;
  role: RoleInterface;
  id: string;
  platform_id: string;
  ability: Array<Ability>;
  employee: {
    id: string;
    names: string;
    surenames: string;
    profileimage: string;
    assigned_shift: {
      shift: {
        id: string;
        name: string;
        resting_days: number | null;
        total_days_periods: number | null;
        total_hours_periods: number | null;
        total_minutes_periods: number | null;
      };
    };
  };
  teams?: TeamInterface[];
}

interface AuthContext {
  user?: UserData;
  initialized?: boolean;
  errorMessage?: string;
  token?: string;
  userImage?: File | null;
  setUserImage: Dispatch<React.SetStateAction<File | null>>;
  signIn: (username: string, password: string, rememberSession: boolean) => void;
  changePassword: (newPassword: string, confirmPassword: string) => void;
  cleanErrorMessage: () => void;
  signOut: () => void;
  refreshSessionToken: () => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContext>({
  signIn: () => null,
  signOut: () => null,
  cleanErrorMessage: () => null,
  changePassword: () => null,
  refreshSessionToken: () => null,
  setUserImage: () => null,
});

const SNACKBAR_SESSION_WILL_EXPIRED_ID = 1;
const parsedIdleSessionTime = process.env.REACT_APP_IDLE_SESSION_TIME
  ? parseSessionTime(process.env.REACT_APP_IDLE_SESSION_TIME)
  : 900000;
const parsedIdleSessionTimeWarning = process.env.REACT_APP_IDLE_SESSION_TIME_WARNING
  ? parseSessionTime(process.env.REACT_APP_IDLE_SESSION_TIME_WARNING)
  : 840000;
const apiKeyStoreName = 'SYNERGY_API_KEY';
const apiRefreshKeyStoreName = 'SYNERGY_API_REFRESH_KEY';

const AuthProvider = ({ children }: AuthProviderProps) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [user, setUser] = useState<UserData>();
  const [userImage, setUserImage] = useState<File | null>(null);
  const [initialized, setInitialized] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [token, setToken] = useState('');
  const onIdle = () => {
    if (user) {
      signOut();
      closeSnackbar(SNACKBAR_SESSION_WILL_EXPIRED_ID);
      enqueueSnackbar('La sesión expiró por inactividad', { variant: 'error' });
    }
  };

  const onAction = () => {
    if (isPrompted()) {
      closeSnackbar(SNACKBAR_SESSION_WILL_EXPIRED_ID);
      resetIdleTimer();
    }
  };

  const onPrompt = () => {
    if (user) {
      enqueueSnackbar('La sesión expirará por inactividad', {
        variant: 'warning',
        persist: true,
        key: SNACKBAR_SESSION_WILL_EXPIRED_ID,
        preventDuplicate: true,
      });
    }
  };

  const { reset: resetIdleTimer, isPrompted } = useIdleTimer({
    onIdle,
    onAction,
    onPrompt,
    timeout: parsedIdleSessionTime,
    promptBeforeIdle: parsedIdleSessionTimeWarning,
    crossTab: true,
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
  });

  const storeApiKey = (apiKey: string) => localStorage.setItem(apiKeyStoreName, apiKey);
  const storeApiRefreshKey = (apiKey: string) => localStorage.setItem(apiRefreshKeyStoreName, apiKey);

  const retrieveApiKey = () => localStorage.getItem(apiKeyStoreName);
  const retrieveRefreshApiKey = () => localStorage.getItem(apiRefreshKeyStoreName);

  const removeApiKey = () => localStorage.removeItem(apiKeyStoreName);

  const setApiKeyOnHeaders = (apiKey: string) => httpService.setAuthHeader({ jWT: apiKey });

  const cleanErrorMessage = () => setErrorMessage('');

  const getUserProfile = async () => {
    try {
      const dataUser = await getDataUser();
      if (dataUser) {
        setUser({
          id: dataUser.userId,
          platform_id: dataUser.platform_id,
          username: dataUser.username,
          firstName: dataUser.firstname,
          lastName: dataUser.lastname,
          role: dataUser.role,
          ability: dataUser.ability,
          employee: dataUser.employee,
          teams: dataUser.teams,
        });
      }
    } catch (error) {
      setErrorMessage('Ocurrio un error al acceder');
    }
  };

  const changePassword = async (newPassword: string, confirmPassword: string) => {
    try {
      await httpService.put({
        url: '/auth/password',
        payload: { username, newPassword, confirmPassword },
        config: {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      });
      getUserProfile();
    } catch (error) {
      setErrorMessage((error as AxiosError).response?.data.message.message);
    }
  };

  const signIn = async (username: string, password: string, rememberSession: boolean) => {
    try {
      const { data } = await httpService.post({
        url: '/auth/login',
        payload: { username, password },
        config: {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      });
      setToken(data.access_token);
      setApiKeyOnHeaders(data.access_token);
      const resDataUser = await getDataUser();

      setUsername(username);

      if (resDataUser.updatePassword) {
        navigate('/change-password');
      } else {
        setUser({
          id: resDataUser.userId,
          platform_id: resDataUser.platform_id,
          username: resDataUser.username,
          firstName: resDataUser.firstname,
          lastName: resDataUser.lastname,
          role: resDataUser.role,
          ability: resDataUser.ability,
          employee: resDataUser.employee,
          teams: resDataUser.teams,
        });
        storeApiRefreshKey(data.refreshToken);
        if (rememberSession) {
          storeApiKey(data.access_token);
        }
      }
    } catch (error) {
      const errMsg = (error as AxiosError).response?.data?.errors[0].constraints?.matches;
      setErrorMessage(errMsg ? errMsg : 'Ocurrio un error al acceder');
    }
  };

  const signOut = () => {
    removeApiKey();
    setToken('');
    setUser(undefined);
  };

  const refreshSessionToken = async () => {
    const apiRefreshKey = retrieveRefreshApiKey();
    const apiOldKey = retrieveApiKey();
    if (apiRefreshKey) {
      try {
        const freshData = await refreshToken();
        if (freshData) {
          setToken(freshData.access_token);
          setApiKeyOnHeaders(freshData.access_token);
          storeApiRefreshKey(freshData.refreshToken);
          if (apiOldKey) {
            storeApiKey(freshData.access_token);
          }
        } else {
          signOut();
        }
      } catch (e) {
        enqueueSnackbar('La sesión expiró por inactividad', { variant: 'error' });
        signOut();
      }
    } else {
      enqueueSnackbar('La sesión expiró por inactividad', { variant: 'error' });
      signOut();
    }
  };

  const initialize = useCallback(async () => {
    const apiKey = retrieveApiKey();
    if (apiKey) {
      setToken(apiKey);
      setApiKeyOnHeaders(apiKey);
      await getUserProfile();
    }
    setInitialized(true);
  }, []);

  const getProfileUser = useCallback(async () => {
    if (user && user.employee.profileimage) {
      const url = await getFile(user.employee.id);
      setUserImage(url);
    }
  }, [user]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    getProfileUser();
    return () => {
      setUserImage(null);
    };
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        userImage,
        setUserImage,
        initialized,
        errorMessage,
        token,
        signIn,
        signOut,
        cleanErrorMessage,
        changePassword,
        refreshSessionToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
