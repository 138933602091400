import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BirthdayInterface,
  HomeElementsSlice,
  Holiday,
  LoadingState,
  PaginatedHomeElement,
  PersonalRequest,
  PersonalScheduler,
  SupervisedHeadcount,
  SupervisedRequestsData,
  SupervisedScheduler,
  SupervisedTeam,
  HomeElementsView,
} from 'modules/home/interfaces';

export const genericDefaultPaginatedObject = {
  data: [],
  meta: {
    page: 1,
    take: 25,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  },
};

const initialState: HomeElementsSlice = {
  loading: {
    supervisedRequests: false,
    birthdays: false,
    holidays: false,
    headcount: false,
    scheduler: false,
    personalRequests: false,
    personalScheduler: false,
    supervisedTeams: false,
    teamsHeadcount: false,
    homeElements: false,
  },
  supervisedRequests: genericDefaultPaginatedObject,
  birthdays: genericDefaultPaginatedObject,
  holidays: [],
  supervisedHeadcount: [],
  scheduler: genericDefaultPaginatedObject,
  personalRequests: genericDefaultPaginatedObject,
  personalScheduler: genericDefaultPaginatedObject,
  supervisedTeams: [],
  view: 'operator',
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ key: keyof LoadingState; value: boolean }>) => {
      state.loading[action.payload.key] = action.payload.value;
    },
    setSupervisedRequests(state, action: PayloadAction<PaginatedHomeElement<SupervisedRequestsData>>) {
      state.supervisedRequests = action.payload;
    },
    setBirthdays(state, action: PayloadAction<PaginatedHomeElement<BirthdayInterface>>) {
      state.birthdays = action.payload;
    },
    setHolidays(state, action: PayloadAction<Holiday[]>) {
      state.holidays = action.payload;
    },
    setSupervisedHeadcount(state, action: PayloadAction<SupervisedHeadcount[]>) {
      state.supervisedHeadcount = action.payload;
    },
    setScheduler(state, action: PayloadAction<PaginatedHomeElement<SupervisedScheduler>>) {
      state.scheduler = action.payload;
    },
    setPersonalRequests(state, action: PayloadAction<PaginatedHomeElement<PersonalRequest>>) {
      state.personalRequests = action.payload;
    },
    setPersonalScheduler(state, action: PayloadAction<PaginatedHomeElement<PersonalScheduler>>) {
      state.personalScheduler = action.payload;
    },
    setSupervisedTeams(state, action: PayloadAction<SupervisedTeam[]>) {
      state.supervisedTeams = action.payload;
    },
    setView(state, action: PayloadAction<HomeElementsView>) {
      state.view = action.payload;
    },
    resetDashboards() {
      return initialState;
    },
  },
});

export const homeActions = homeSlice.actions;

export default homeSlice;
