import { Ability, useAuth } from 'modules/auth/AuthProvider.component';
import Campaña from 'assets/icons/navbar/Campa.svg';
import Estadistica from 'assets/icons/navbar/Estadistica.svg';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Gestion from 'assets/icons/navbar/Gestion.svg';
import Inicio from 'assets/icons/navbar/Inicio.svg';
import Novedades from 'assets/icons/navbar/Novedades.svg';
import Reportes from 'assets/icons/navbar/Reportes.svg';

export interface MenuItem {
  label: string;
  to: string;
  indicator?: string;
  hidden?: boolean;
}
export interface MenuContent {
  label: string;
  icon: JSX.Element;
  items: MenuItem[];
  to: string;
  hidden?: boolean;
}

export const getMenuContent = (permissions: Array<Ability>): MenuContent[] => {
  const { user } = useAuth();

  const menuContent: MenuContent[] = [
    {
      label: 'Inicio',
      icon: <img src={Inicio} style={{ width: '30px' }} />,
      to: '/',
      hidden:
        !permissions.some(permission => permission.name === 'list_campaign') &&
        !permissions.some(permission => permission.name === 'dashboard_manager') &&
        !permissions.some(permission => permission.name === 'dashboard_operador'),
      items: [],
    },
    {
      label: 'Dashboard',
      icon: <img src={Estadistica} style={{ width: '30px' }} />,
      to: '#',
      hidden:
        !permissions.some(permission => permission.name === 'list_campaign') &&
        !permissions.some(permission => permission.name === 'dashboard_manager') &&
        !permissions.some(permission => permission.name === 'dashboard_operador'),
      items: [
        {
          label: 'Mi Dashboard',
          to: '/reports/my-dashboard',
          hidden:
            !permissions.some(permission => permission.name === 'list_campaign') ||
            !permissions.some(permission => permission.name === 'dashboard_manager'),
        },
        {
          label: 'Mi Dashboard Operativo',
          to: '/reports/my-dashboard-operator',
          hidden: !permissions.some(permission => permission.name === 'dashboard_operador'),
        },
      ],
    },
    {
      label: 'Reportes',
      icon: <img src={Reportes} style={{ width: '30px' }} />,
      to: '/reports',
      hidden: !permissions.some(permission => permission.name === 'execute_report'),
      items: [],
    },
    {
      label: 'Novedades',
      icon: <img src={Novedades} style={{ width: '30px' }} />,
      to: '/requests',
      hidden:
        (user?.employee === null || !permissions.some(permission => permission.name === 'list_license')) &&
        !permissions.some(permission => permission.name === 'list_charged_by_me_request_license') &&
        !permissions.some(permission => permission.name === 'list_revisions_request_license'),
      items: [],
    },
    {
      label: 'Planificación',
      icon: <AnalyticsIcon sx={{ width: 30 }} />,
      to: '#',
      hidden: false,
      items: [
        { label: 'Turnos', to: '/shifts' },
        { label: 'Planificador', to: '/schedulers' },
        {
          label: 'Equipos',
          to: '/equipos',
          hidden: !permissions.some(permission => permission.name === 'list_team'),
        },
      ],
    },
    {
      label: 'Gestión',
      icon: <img src={Gestion} style={{ width: '30px' }} />,
      to: '#',
      hidden:
        !permissions.some(permission => permission.name === 'list_clients') &&
        !permissions.some(permission => permission.name === 'read_client') &&
        !permissions.some(permission => permission.name === 'create_tag') &&
        !permissions.some(permission => permission.name === 'update_tag') &&
        !permissions.some(permission => permission.name === 'list_tags_by_category') &&
        !permissions.some(permission => permission.name === 'list_tag') &&
        !permissions.some(permission => permission.name === 'delete_tag') &&
        !permissions.some(permission => permission.name === 'list_employee') &&
        !permissions.some(permission => permission.name === 'list_training'),
      items: [
        {
          label: 'Alertas',
          to: '/alerts',
          hidden: !permissions.some(permission => permission.name === 'list_notification'),
        },
        { label: 'Roles', to: '/roles', hidden: !permissions.some(permission => permission.name === 'list_role') },
        {
          label: 'Clientes',
          to: '/clients',
          hidden:
            !permissions.some(permission => permission.name === 'list_clients') ||
            !permissions.some(permission => permission.name === 'read_client'),
        },
        {
          label: 'Tags',
          to: '/tags',
          hidden:
            !permissions.some(permission => permission.name === 'create_tag') ||
            !permissions.some(permission => permission.name === 'update_tag') ||
            !permissions.some(permission => permission.name === 'list_tags_by_category') ||
            !permissions.some(permission => permission.name === 'list_tag') ||
            !permissions.some(permission => permission.name === 'delete_tag'),
        },
        {
          label: 'Empleados',
          to: '/employees',
          hidden: !permissions.some(permission => permission.name === 'list_employee'),
        },
        {
          label: 'Capacitaciones',
          to: '/trainings',
          hidden: !permissions.some(permission => permission.name === 'list_training'),
        },
      ],
    },
    {
      label: 'Campañas',
      icon: <img src={Campaña} style={{ width: '30px' }} />,
      to: '#',
      hidden:
        !permissions.some(permission => permission.name === 'list_campaign') &&
        (!permissions.some(permission => permission.name === 'create_campaign') ||
          !permissions.some(permission => permission.name === 'update_campaign') ||
          !permissions.some(permission => permission.name === 'list_user') ||
          !permissions.some(permission => permission.name === 'list_skill') ||
          !permissions.some(permission => permission.name === 'list_tag')) &&
        (!permissions.some(permission => permission.name === 'create_outbound_plan') ||
          !permissions.some(permission => permission.name === 'read_outbound_plan') ||
          !permissions.some(permission => permission.name === 'update_outbound_plan') ||
          !permissions.some(permission => permission.name === 'list_campaign') ||
          !permissions.some(permission => permission.name === 'list_user') ||
          !permissions.some(permission => permission.name === 'list_tag')) &&
        (!permissions.some(permission => permission.name === 'ds_list_inbound') ||
          !permissions.some(permission => permission.name === 'create_inbound_calculation') ||
          !permissions.some(permission => permission.name === 'list_campaign')),
      items: [
        {
          label: 'Ver Campañas',
          to: '/campaigns',
          hidden: !permissions.some(permission => permission.name === 'list_campaign'),
        },
        {
          label: 'Nueva Campaña',
          to: '/campaigns/new',
          hidden:
            !permissions.some(permission => permission.name === 'create_campaign') ||
            !permissions.some(permission => permission.name === 'update_campaign') ||
            !permissions.some(permission => permission.name === 'list_user') ||
            !permissions.some(permission => permission.name === 'list_skill') ||
            !permissions.some(permission => permission.name === 'list_tag'),
        },
        // {
        //   label: 'Crear Pronóstico Outbound',
        //   to: '/campaigns/outbound/new',
        //   hidden:
        //     !permissions.some(permission => permission.name === 'create_outbound_plan') ||
        //     !permissions.some(permission => permission.name === 'read_outbound_plan') ||
        //     !permissions.some(permission => permission.name === 'update_outbound_plan') ||
        //     !permissions.some(permission => permission.name === 'list_campaign') ||
        //     !permissions.some(permission => permission.name === 'list_user') ||
        //     !permissions.some(permission => permission.name === 'list_tag'),
        // },
        // {
        //   label: 'Calculadora Inbound',
        //   to: '/inbound-calculator',
        //   hidden:
        //     !permissions.some(permission => permission.name === 'ds_list_inbound') ||
        //     !permissions.some(permission => permission.name === 'create_inbound_calculation') ||
        //     !permissions.some(permission => permission.name === 'list_campaign'),
        // },
      ],
    },
  ];
  return menuContent;
};
